import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card flex justify-content-center"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["id", "onClick", "onMouseover", "onMouseleave"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = ["onUpdate:modelValue", "onKeypress"]
const _hoisted_10 = {
  key: 0,
  class: "white-space-nowrap overflow-hidden text-overflow-ellipsis max-w-26rem"
}
const _hoisted_11 = { class: "white-space-nowrap overflow-hidden text-overflow-ellipsis max-w-26rem" }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { key: 3 }
const _hoisted_15 = ["onClick", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Image = _resolveComponent("Image")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_TableView = _resolveComponent("TableView", true)!

  return (_openBlock(), _createBlock(_component_TableView, {
    id: "document-table",
    ref: "docTable",
    selected_items: _ctx.componentData.selected_documents,
    "onUpdate:selected_items": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.componentData.selected_documents) = $event)),
    selected_item: _ctx.componentData.selected_document,
    "onUpdate:selected_item": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.componentData.selected_document) = $event)),
    class: _normalizeClass({ 'clickable-table': _ctx.clickable }),
    permission_type: _ctx.permission,
    highlight_doc: _ctx.highlight_doc,
    contextMenu: true,
    stripedRows: false,
    context_items: _ctx.context_items,
    items: _ctx.items,
    fields: _ctx.fields,
    pagination: _ctx.pagination,
    moving_doc: _ctx.moving_doc,
    drag_selector: _ctx.drag_selector,
    disable_select: _ctx.disable_select,
    dragged_over_doc: _ctx.componentData.draggedOverDoc,
    allow_drag_drop: true,
    target: _ctx.target,
    onLoadMoreData: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('load-more-docs'))),
    onUpdateMenu: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update-menu'))),
    onDragStarted: _ctx.dragStarted,
    onDraggedOver: _ctx.draggedOver,
    onDragLeave: _ctx.dragLeaved,
    onDragStoped: _ctx.dragStopped,
    onDroppedItem: _ctx.droppedItem
  }, {
    footer: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ProgressSpinner, {
              style: {"width":"30px","height":"30px"},
              strokeWidth: "5",
              animationDuration: ".5s",
              "aria-label": "Loading"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    "col-data": _withCtx(({ data }) => [
      (data.key == 'name')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              id: data.data.id,
              class: _normalizeClass([{
            'opacity-60':
              (_ctx.moving_doc.type == 'cut' &&
                _ctx.moving_doc.detail.id == data.data.id) ||
              data.data.text_extraction_pending ||
              _ctx.permission == 'trash',
          }, "selectable-item flex justify-content-start flex-wrap"]),
              onClick: ($event: any) => (_ctx.onItemClick($event, data.data)),
              onMouseover: ($event: any) => (
            data.data.renaming || data.data.new
              ? null
              : _ctx.togglePopover($event, data.data)
          ),
              onMouseleave: ($event: any) => (
            data.data.renaming || data.data.new
              ? null
              : _ctx.togglePopover($event, data.data, false)
          )
            }, [
              _createElementVNode("div", {
                class: _normalizeClass([_ctx.properties.$getContentType(data.data), "flex align-items-center justify-content-center document-icon mr-1"])
              }, [
                (_ctx.properties.$getContentType(data.data) == 'image')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      _createVNode(_component_Image, {
                        width: "32",
                        src: _ctx.properties.$getFullPath(data.data.path),
                        class: "flex align-content-center flex-wrap",
                        alt: data.value
                      }, null, 8, ["src", "alt"])
                    ]))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      innerHTML: _ctx.properties.$getIcon(data.data)
                    }, null, 8, _hoisted_5)),
                (data.data.shared)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _cache[6] || (_cache[6] = [
                      _createElementVNode("i", { class: "overlap-icon shared-icon fas fa-share-alt" }, null, -1)
                    ])))
                  : _createCommentVNode("", true),
                (data.data.starred)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _cache[7] || (_cache[7] = [
                      _createElementVNode("i", { class: "overlap-icon starred-icon fas fa-star" }, null, -1)
                    ])))
                  : _createCommentVNode("", true),
                (data.data.pinned)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _cache[8] || (_cache[8] = [
                      _createElementVNode("i", { class: "overlap-icon pinned-icon fa-solid fa-thumbtack" }, null, -1)
                    ])))
                  : _createCommentVNode("", true)
              ], 2),
              _createElementVNode("div", {
                class: _normalizeClass([[_ctx.activities_view ? 'w-5' : 'w-9'], "flex align-items-center justify-content-start"])
              }, [
                (data.data.renaming || data.data.new)
                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                      key: 0,
                      class: "p-inputtext p-component p-filled",
                      "onUpdate:modelValue": ($event: any) => ((data.data.newName) = $event),
                      ref: `docName${data.data.id}`,
                      onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:disabled_selection', true))),
                      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:disabled_selection', false))),
                      onKeypress: _withKeys(($event: any) => (
                data.data.new
                  ? _ctx.$emit('create-document', data.data.newName)
                  : _ctx.$emit('update-document', data.data.newName)
              ), ["enter"])
                    }, null, 40, _hoisted_9)), [
                      [_vModelText, data.data.newName]
                    ])
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["white-space-nowrap overflow-hidden text-overflow-ellipsis", [_ctx.activities_view ? 'max-w-10rem' : 'max-w-18rem']])
                    }, _toDisplayString(data.value), 3))
              ], 2)
            ], 42, _hoisted_3),
            _createVNode(_component_OverlayPanel, {
              ref: `tablePopOver${data.data.id}`,
              appendTo: "body"
            }, {
              default: _withCtx(() => [
                _createElementVNode("ul", null, [
                  (
                _ctx.view_parent &&
                data.data.parent_project &&
                data.data.type != 'project'
              )
                    ? (_openBlock(), _createElementBlock("li", _hoisted_10, " Parent Project: " + _toDisplayString(data.data.parent_project.name), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("li", _hoisted_11, " Name: " + _toDisplayString(data.data.name), 1),
                  (!_ctx.disable_select)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_12, " Created at: " + _toDisplayString(_ctx.properties.$dayMonthDateYear(data.data.created_at)), 1))
                    : _createCommentVNode("", true),
                  (data.data.metadata && !_ctx.disable_select)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_13, " Size: " + _toDisplayString(_ctx.properties.$getFileSize(
                  _ctx.properties.app_service.parseJSON(data.data.metadata).size
                )), 1))
                    : _createCommentVNode("", true),
                  (!_ctx.disable_select)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, " Owner: " + _toDisplayString(data.data.owner.name ? data.data.owner.name : data.data.owner), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 2
            }, 1536)
          ]))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            onClick: ($event: any) => (_ctx.$emit('load-doc-detail', data)),
            innerHTML: data.value,
            class: _normalizeClass({
          'opacity-60':
            (_ctx.moving_doc.type == 'cut' &&
              _ctx.moving_doc.detail.id == data.data.id) ||
            data.data.text_extraction_pending,
        })
          }, null, 10, _hoisted_15))
    ]),
    _: 1
  }, 8, ["selected_items", "selected_item", "class", "permission_type", "highlight_doc", "context_items", "items", "fields", "pagination", "moving_doc", "drag_selector", "disable_select", "dragged_over_doc", "target", "onDragStarted", "onDraggedOver", "onDragLeave", "onDragStoped", "onDroppedItem"]))
}