<template>
  <div id="form-content" class="page-content">
    <Form ref="createReport" class="form-wrap">
      <div class="field grid">
        <label for="title" class="col-12 mb-2 md:col-3 md:mb-0"
          >Title <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="title"
            name="title"
            rules="required"
            autocomplete="off"
            v-model="self.report.title"
          >
            <InputText type="text" v-model="self.report.title" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="title" />
        </div>
      </div>
      <div class="field grid">
        <label for="description" class="col-12 mb-2 md:col-3 md:mb-0"
          >Description
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="description"
            name="description"
            autocomplete="off"
            v-model="self.report.description"
          >
            <Textarea v-model="self.report.description" />
          </Field>
          <ErrorMessage as="small" class="p-error" name="description" />
        </div>
      </div>

      <div class="field grid">
        <label for="report-type" class="col-12 mb-2 md:col-3 md:mb-0"
          >Report Type <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="report-type"
            name="report-type"
            rules="required"
            autocomplete="off"
            v-model="self.report.report_type"
          >
            <Dropdown
              v-model="self.report.report_type"
              :options="options.types"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a Report Type"
              class="w-full"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="report-type" />
        </div>
      </div>
      <div v-if="self.report.report_type == 'user-activity'" class="field grid">
        <label for="user" class="col-12 mb-2 md:col-3 md:mb-0"
          >Users <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="output"
            name="user"
            rules="required"
            autocomplete="off"
            v-model="self.report.users"
          >
            <MultiSelect
              v-model="self.report.users"
              display="chip"
              :options="options.users.data"
              optionLabel="name"
              optionValue="id"
              filter
              class="w-full"
              :loading="options.users.loading"
              :emptyMessage="options.users.emptyFilterMessage"
              :emptyFilterMessage="options.users.emptyFilterMessage"
              @filter="
                $emit('search-users', {
                  originalEvent: $event,
                  selected_users: self.report.users,
                })
              "
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="user" />
        </div>
      </div>

      <div class="field grid">
        <label for="output" class="col-12 mb-2 md:col-3 md:mb-0"
          >Output <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="output"
            name="output"
            rules="required"
            autocomplete="off"
            v-model="self.report.output"
          >
            <Dropdown
              v-model="self.report.output"
              :options="options.outputs"
              optionLabel="name"
              optionValue="slug"
              placeholder="Select an Output"
              class="w-full"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="output" />
        </div>
      </div>
      <div class="field grid">
        <label for="frequency" class="col-12 mb-2 md:col-3 md:mb-0"
          >Frequency <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="frequency"
            name="frequency"
            rules="required"
            autocomplete="off"
            v-model="self.report.frequency"
          >
            <Dropdown
              v-model="self.report.frequency"
              :options="options.frequencies"
              optionLabel="name"
              optionValue="slug"
              placeholder="Select a Frequency"
              class="w-full"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="frequency" />
        </div>
      </div>
      <div
        class="field grid"
        v-if="
          self.report.report_type != 'user-activity' &&
          self.report.report_type != 'quarantine-file' &&
          self.report.report_type != 'nda-signed' &&
          self.report.report_type != 'user-login-activity'
        "
      >
        <label for="frequency" class="col-12 mb-2 md:col-3 md:mb-0"
          >Tag <small class="ml-2 p-error">*</small></label
        >
        <div class="col-12 md:col-9">
          <div
            class="field grid"
            v-for="(tag, index) in self.report.tag"
            :key="index"
          >
            <div class="col-12 md:col-5">
              <Field
                class="w-full"
                as="div"
                :id="`tag-key-${index + 1}`"
                :name="`Tag Key #${index + 1}`"
                rules="required"
                autocomplete="off"
                v-model="tag.key"
              >
                <Dropdown
                  v-model="tag.key"
                  :options="options.tags"
                  optionLabel="name"
                  optionValue="slug"
                  placeholder="Select a Tag key"
                  class="w-full"
                  editable
                />
              </Field>
              <ErrorMessage
                as="small"
                class="p-error"
                :name="`Tag Key #${index + 1}`"
              />
            </div>
            <div class="col-12 md:col-5">
              <Field
                class="w-full"
                as="div"
                :id="`tag-value-${index + 1}`"
                :name="`Tag Value #${index + 1}`"
                rules="required"
                autocomplete="off"
                v-model="tag.value"
              >
                <InputText type="text" v-model="tag.value" />
              </Field>
              <ErrorMessage
                as="small"
                class="p-error"
                :name="`Tag Value #${index + 1}`"
              />
            </div>
            <div class="col-12 md:col-2">
              <Button
                v-if="index == self.report.tag.length - 1"
                icon="icofont icofont-plus"
                severity="success"
                rounded
                aria-label="Add"
                class="button-sm mr-2"
                @click="addTag"
              />
              <Button
                v-if="self.report.tag.length != 1"
                icon="icofont icofont-close"
                severity="danger"
                rounded
                aria-label="Remove"
                class="button-sm"
                @click="removeTag(index)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="field grid">
        <label for="start-date" class="col-12 mb-2 md:col-3 md:mb-0"
          >Start Date
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="start-date"
            name="Start Date"
            autocomplete="off"
            v-model="self.report.start_date"
          >
            <Calendar
              v-model="self.report.start_date"
              dateFormat="yy-mm-dd"
              :showIcon="false"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="Start Date" />
        </div>
      </div>
      <div class="field grid">
        <label for="end-date" class="col-12 mb-2 md:col-3 md:mb-0"
          >End Date
        </label>
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            as="div"
            type="text"
            id="end-date"
            name="End Date"
            autocomplete="off"
            v-model="self.report.end_date"
          >
            <Calendar
              v-model="self.report.end_date"
              dateFormat="yy-mm-dd"
              :showIcon="false"
            />
          </Field>
          <ErrorMessage as="small" class="p-error" name="End Date" />
        </div>
      </div>

      <div class="field grid">
        <label for="end-date" class="col-12 mb-2 md:col-3 md:mb-0">Self Notify</label>
        <div class="col-12 md:col-9">
          <div class="w-full">
            <Checkbox v-model="self.report.self_notify" binary />
          </div>
        </div>
      </div>

      <div class="field grid">
        <label for="end-date" class="col-12 mb-2 md:col-3 md:mb-0"
          >Recipient</label
        >
        <div class="col-12 md:col-9">
          <Field
            class="w-full"
            v-slot="{ field }"
            as="div"
            type="text"
            id="recipient"
            name="Recipient"
            autocomplete="off"
          >
            <Chips
              :allowDuplicate="false"
              v-bind="field"
              v-model="self.report.recipients"
              separator=","
            />
            <ErrorMessage as="small" class="p-error" name="Recipient" />
          </Field>
        </div>
      </div>

      <div class="flex align-items-center justify-content-end mt-2">
        <Button
          type="submit"
          label="Generate Report"
          :loading="self.creating_report"
          @click.prevent="createNewReport"
          class="button-primary"
        />
      </div>
    </Form>
  </div>
</template>
<script setup lang="ts">
import { reactive, ref, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";
const { appContext }: any = getCurrentInstance();
defineProps({
  options: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
const createReport = ref();
const emit = defineEmits(["created-report", "search-users"]);
const self = reactive({
  api_url: "reports",
  store: useStore(),
  current_user: useStore().getters["setting/getUserDetail"],
  report: {
    title: null,
    description: null,
    report_type: null,
    output: null,
    frequency: null,
    tag: [
      {
        key: null,
        value: null,
      },
    ],
    start_date: null,
    end_date: null,
    self_notify: false,
    recipients: [],
  } as any,
  creating_report: false,
});

watch(
  () => self.report.self_notify,
  (val: boolean) => {
    if (val) {
      if (self.report.recipients.includes(self.current_user.email)) return;
      self.report.recipients.push(self.current_user.email);
    }
  }
);

async function createNewReport() {
  const validate = await createReport.value.validate();
  if (!validate.valid) return;
  self.creating_report = true;
  const report_data: any = Object.assign({}, self.report);
  const report_data_tag = self.report.tag.map((report: any) => { return { [report.key]: report.value, }; });
  report_data.tag = appContext.config.globalProperties.$arrayToObject(report_data_tag);
  report_data.start_date = appContext.config.globalProperties.$YMD(self.report.start_date);
  report_data.end_date = appContext.config.globalProperties.$YMD(self.report.end_date);
  console.log(report_data);
  await appContext.config.globalProperties.app_service.createData(
    self.api_url,
    report_data,
    (response: any, error: any) => {
      self.creating_report = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-report", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

function addTag() {
  self.report.tag.push({
    key: null,
    value: null,
  });
}

function removeTag(index: number) {
  self.report.tag.splice(index, 1);
}
</script>