import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  id: "form-content",
  class: "page-content"
}
const _hoisted_2 = { class: "field grid" }
const _hoisted_3 = { class: "col-12 md:col-9" }
const _hoisted_4 = { class: "field grid" }
const _hoisted_5 = { class: "col-12 md:col-9" }
const _hoisted_6 = { class: "field grid" }
const _hoisted_7 = { class: "col-12 md:col-9" }
const _hoisted_8 = {
  key: 0,
  class: "field grid"
}
const _hoisted_9 = { class: "col-12 md:col-9" }
const _hoisted_10 = { class: "field grid" }
const _hoisted_11 = { class: "col-12 md:col-9" }
const _hoisted_12 = { class: "field grid" }
const _hoisted_13 = { class: "col-12 md:col-9" }
const _hoisted_14 = {
  key: 1,
  class: "field grid"
}
const _hoisted_15 = { class: "col-12 md:col-9" }
const _hoisted_16 = { class: "col-12 md:col-5" }
const _hoisted_17 = { class: "col-12 md:col-5" }
const _hoisted_18 = { class: "col-12 md:col-2" }
const _hoisted_19 = { class: "field grid" }
const _hoisted_20 = { class: "col-12 md:col-9" }
const _hoisted_21 = { class: "field grid" }
const _hoisted_22 = { class: "col-12 md:col-9" }
const _hoisted_23 = { class: "field grid" }
const _hoisted_24 = { class: "col-12 md:col-9" }
const _hoisted_25 = { class: "w-full" }
const _hoisted_26 = { class: "field grid" }
const _hoisted_27 = { class: "col-12 md:col-9" }
const _hoisted_28 = { class: "flex align-items-center justify-content-end mt-2" }

import { reactive, ref, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";

export default /*@__PURE__*/_defineComponent({
  __name: 'generateReport',
  props: {
  options: {
    type: Object,
    default: () => {
      return {};
    },
  },
},
  emits: ["created-report", "search-users"],
  setup(__props, { emit: __emit }) {

const { appContext }: any = getCurrentInstance();

const createReport = ref();
const emit = __emit;
const self = reactive({
  api_url: "reports",
  store: useStore(),
  current_user: useStore().getters["setting/getUserDetail"],
  report: {
    title: null,
    description: null,
    report_type: null,
    output: null,
    frequency: null,
    tag: [
      {
        key: null,
        value: null,
      },
    ],
    start_date: null,
    end_date: null,
    self_notify: false,
    recipients: [],
  } as any,
  creating_report: false,
});

watch(
  () => self.report.self_notify,
  (val: boolean) => {
    if (val) {
      if (self.report.recipients.includes(self.current_user.email)) return;
      self.report.recipients.push(self.current_user.email);
    }
  }
);

async function createNewReport() {
  const validate = await createReport.value.validate();
  if (!validate.valid) return;
  self.creating_report = true;
  const report_data: any = Object.assign({}, self.report);
  const report_data_tag = self.report.tag.map((report: any) => { return { [report.key]: report.value, }; });
  report_data.tag = appContext.config.globalProperties.$arrayToObject(report_data_tag);
  report_data.start_date = appContext.config.globalProperties.$YMD(self.report.start_date);
  report_data.end_date = appContext.config.globalProperties.$YMD(self.report.end_date);
  console.log(report_data);
  await appContext.config.globalProperties.app_service.createData(
    self.api_url,
    report_data,
    (response: any, error: any) => {
      self.creating_report = false;
      if (response) {
        appContext.config.globalProperties.$toastMessage(response);
        if (response.status >= 200 && response.status < 300) {
          emit("created-report", response.data.data, true);
        }
      }
      if (error) {
        appContext.config.globalProperties.$toastMessage(
          appContext.config.globalProperties.$getErrorResponse(error)
        );
      }
    }
  );
}

function addTag() {
  self.report.tag.push({
    key: null,
    value: null,
  });
}

function removeTag(index: number) {
  self.report.tag.splice(index, 1);
}

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Chips = _resolveComponent("Chips")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      ref_key: "createReport",
      ref: createReport,
      class: "form-wrap"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _cache[19] || (_cache[19] = _createElementVNode("label", {
            for: "title",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Title "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "title",
              name: "title",
              rules: "required",
              autocomplete: "off",
              modelValue: self.report.title,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((self.report.title) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: self.report.title,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((self.report.title) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "title"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[20] || (_cache[20] = _createElementVNode("label", {
            for: "description",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "Description ", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "description",
              name: "description",
              autocomplete: "off",
              modelValue: self.report.description,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((self.report.description) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Textarea, {
                  modelValue: self.report.description,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((self.report.description) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "description"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[21] || (_cache[21] = _createElementVNode("label", {
            for: "report-type",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Report Type "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "report-type",
              name: "report-type",
              rules: "required",
              autocomplete: "off",
              modelValue: self.report.report_type,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((self.report.report_type) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: self.report.report_type,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((self.report.report_type) = $event)),
                  options: __props.options.types,
                  optionLabel: "label",
                  optionValue: "value",
                  placeholder: "Select a Report Type",
                  class: "w-full"
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "report-type"
            })
          ])
        ]),
        (self.report.report_type == 'user-activity')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[22] || (_cache[22] = _createElementVNode("label", {
                for: "user",
                class: "col-12 mb-2 md:col-3 md:mb-0"
              }, [
                _createTextVNode("Users "),
                _createElementVNode("small", { class: "ml-2 p-error" }, "*")
              ], -1)),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Field, {
                  class: "w-full",
                  as: "div",
                  type: "text",
                  id: "output",
                  name: "user",
                  rules: "required",
                  autocomplete: "off",
                  modelValue: self.report.users,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((self.report.users) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MultiSelect, {
                      modelValue: self.report.users,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((self.report.users) = $event)),
                      display: "chip",
                      options: __props.options.users.data,
                      optionLabel: "name",
                      optionValue: "id",
                      filter: "",
                      class: "w-full",
                      loading: __props.options.users.loading,
                      emptyMessage: __props.options.users.emptyFilterMessage,
                      emptyFilterMessage: __props.options.users.emptyFilterMessage,
                      onFilter: _cache[7] || (_cache[7] = ($event: any) => (
                _ctx.$emit('search-users', {
                  originalEvent: $event,
                  selected_users: self.report.users,
                })
              ))
                    }, null, 8, ["modelValue", "options", "loading", "emptyMessage", "emptyFilterMessage"])
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  as: "small",
                  class: "p-error",
                  name: "user"
                })
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _cache[23] || (_cache[23] = _createElementVNode("label", {
            for: "output",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Output "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "output",
              name: "output",
              rules: "required",
              autocomplete: "off",
              modelValue: self.report.output,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((self.report.output) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: self.report.output,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((self.report.output) = $event)),
                  options: __props.options.outputs,
                  optionLabel: "name",
                  optionValue: "slug",
                  placeholder: "Select an Output",
                  class: "w-full"
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "output"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[24] || (_cache[24] = _createElementVNode("label", {
            for: "frequency",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, [
            _createTextVNode("Frequency "),
            _createElementVNode("small", { class: "ml-2 p-error" }, "*")
          ], -1)),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "frequency",
              name: "frequency",
              rules: "required",
              autocomplete: "off",
              modelValue: self.report.frequency,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((self.report.frequency) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: self.report.frequency,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((self.report.frequency) = $event)),
                  options: __props.options.frequencies,
                  optionLabel: "name",
                  optionValue: "slug",
                  placeholder: "Select a Frequency",
                  class: "w-full"
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "frequency"
            })
          ])
        ]),
        (
          self.report.report_type != 'user-activity' &&
          self.report.report_type != 'quarantine-file' &&
          self.report.report_type != 'nda-signed' &&
          self.report.report_type != 'user-login-activity'
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _cache[25] || (_cache[25] = _createElementVNode("label", {
                for: "frequency",
                class: "col-12 mb-2 md:col-3 md:mb-0"
              }, [
                _createTextVNode("Tag "),
                _createElementVNode("small", { class: "ml-2 p-error" }, "*")
              ], -1)),
              _createElementVNode("div", _hoisted_15, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(self.report.tag, (tag, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "field grid",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_Field, {
                        class: "w-full",
                        as: "div",
                        id: `tag-key-${index + 1}`,
                        name: `Tag Key #${index + 1}`,
                        rules: "required",
                        autocomplete: "off",
                        modelValue: tag.key,
                        "onUpdate:modelValue": ($event: any) => ((tag.key) = $event)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Dropdown, {
                            modelValue: tag.key,
                            "onUpdate:modelValue": ($event: any) => ((tag.key) = $event),
                            options: __props.options.tags,
                            optionLabel: "name",
                            optionValue: "slug",
                            placeholder: "Select a Tag key",
                            class: "w-full",
                            editable: ""
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                        ]),
                        _: 2
                      }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue"]),
                      _createVNode(_component_ErrorMessage, {
                        as: "small",
                        class: "p-error",
                        name: `Tag Key #${index + 1}`
                      }, null, 8, ["name"])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_Field, {
                        class: "w-full",
                        as: "div",
                        id: `tag-value-${index + 1}`,
                        name: `Tag Value #${index + 1}`,
                        rules: "required",
                        autocomplete: "off",
                        modelValue: tag.value,
                        "onUpdate:modelValue": ($event: any) => ((tag.value) = $event)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_InputText, {
                            type: "text",
                            modelValue: tag.value,
                            "onUpdate:modelValue": ($event: any) => ((tag.value) = $event)
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1032, ["id", "name", "modelValue", "onUpdate:modelValue"]),
                      _createVNode(_component_ErrorMessage, {
                        as: "small",
                        class: "p-error",
                        name: `Tag Value #${index + 1}`
                      }, null, 8, ["name"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      (index == self.report.tag.length - 1)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            icon: "icofont icofont-plus",
                            severity: "success",
                            rounded: "",
                            "aria-label": "Add",
                            class: "button-sm mr-2",
                            onClick: addTag
                          }))
                        : _createCommentVNode("", true),
                      (self.report.tag.length != 1)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 1,
                            icon: "icofont icofont-close",
                            severity: "danger",
                            rounded: "",
                            "aria-label": "Remove",
                            class: "button-sm",
                            onClick: ($event: any) => (removeTag(index))
                          }, null, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_19, [
          _cache[26] || (_cache[26] = _createElementVNode("label", {
            for: "start-date",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "Start Date ", -1)),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "start-date",
              name: "Start Date",
              autocomplete: "off",
              modelValue: self.report.start_date,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((self.report.start_date) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Calendar, {
                  modelValue: self.report.start_date,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((self.report.start_date) = $event)),
                  dateFormat: "yy-mm-dd",
                  showIcon: false
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "Start Date"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _cache[27] || (_cache[27] = _createElementVNode("label", {
            for: "end-date",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "End Date ", -1)),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "end-date",
              name: "End Date",
              autocomplete: "off",
              modelValue: self.report.end_date,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((self.report.end_date) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Calendar, {
                  modelValue: self.report.end_date,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((self.report.end_date) = $event)),
                  dateFormat: "yy-mm-dd",
                  showIcon: false
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_ErrorMessage, {
              as: "small",
              class: "p-error",
              name: "End Date"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _cache[28] || (_cache[28] = _createElementVNode("label", {
            for: "end-date",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "Self Notify", -1)),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createVNode(_component_Checkbox, {
                modelValue: self.report.self_notify,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((self.report.self_notify) = $event)),
                binary: ""
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _cache[29] || (_cache[29] = _createElementVNode("label", {
            for: "end-date",
            class: "col-12 mb-2 md:col-3 md:mb-0"
          }, "Recipient", -1)),
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_Field, {
              class: "w-full",
              as: "div",
              type: "text",
              id: "recipient",
              name: "Recipient",
              autocomplete: "off"
            }, {
              default: _withCtx(({ field }) => [
                _createVNode(_component_Chips, _mergeProps({ allowDuplicate: false }, field, {
                  modelValue: self.report.recipients,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((self.report.recipients) = $event)),
                  separator: ","
                }), null, 16, ["modelValue"]),
                _createVNode(_component_ErrorMessage, {
                  as: "small",
                  class: "p-error",
                  name: "Recipient"
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_28, [
          _createVNode(_component_Button, {
            type: "submit",
            label: "Generate Report",
            loading: self.creating_report,
            onClick: _withModifiers(createNewReport, ["prevent"]),
            class: "button-primary"
          }, null, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}
}

})